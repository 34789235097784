import React, { Component } from 'react';
import LandingPage from '../Landing';
import ContainerLookupPage from '../ContainerLookup';

import { 
    BrowserRouter as Router,   
    Route,
} from 'react-router-dom';

import Navigation from '../Navigation';
import * as ROUTES from '../../constants/routes';

import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';


class App extends Component {    

    render() {
        return (
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route exact path={ROUTES.LANDING} component={LandingPage} />
                        <Route path={ROUTES.CONTAINER_LOOKUP} component={ContainerLookupPage} />
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        )
    }
}

export default App;

