import React from 'react';
import  { FirebaseContext } from '../Firebase';

class ContainerLookup extends React.Component {
  
    render() {
        return (
             <div>Landing Page</div>
        );
    }
}

export default ContainerLookup