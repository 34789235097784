import React from 'react';
import db from '../../firebaseConfig';
import { 
    IonPage, IonHeader, IonToolbar, IonTitle, 
    IonContent, IonDatetime, IonButton, IonLabel, 
    IonList, IonItem, IonGrid, IonCol, IonRow, IonBadge, IonIcon,
    IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent
} 
from '@ionic/react';
import './index.css';

class ContainerLookupPage extends React.Component {
  
    constructor(props){
        super(props);
        this.state = {done: false};
    }

    getDateString(date) {
        var day = (date.getDay()+ 1) + "";
        var month = (date.getMonth() + 1) + "";
        var year = date.getFullYear() + "";
        var hours = date.getHours() + "";
        var minutes = date.getMinutes() + "";
        
        var suffix = hours >= 12 ? "PM":"AM"; 
        hours = ((hours + 11) % 12 + 1); 

        hours = this.checkZero(hours);
        minutes = this.checkZero(minutes);

        return (month + "/" + day + "/" + year + " " + hours + ":" + minutes + " " + suffix);

    }

    checkZero(data) {
        if(data.length === 1){
            data = "0" + data;
        }
        return data;
    }

    componentDidMount() {
        const pathName = this.props.location.pathname;
        const containerId = pathName.substring(pathName.lastIndexOf('/') + 1);

        if(containerId) {
            db.collection('containers')
            .doc(containerId)
            .get()
            .then( (container) => {
                var data = container.data();
                data.done = true;

                this.setState({...data});
            }).catch( (error) => { console.log(error) });
        }
    }

    render() {
        if (!this.state.done) {
            return <div/>
        }

        return (
            <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonIcon name="document"></IonIcon>
                    <IonTitle>SmartCert Scan Result</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>{this.state.name}</IonCardTitle>
                    </IonCardHeader>
                    
                    <IonCardContent>
                        <IonRow>
                            <IonCol>
                                <IonRow>
                                    <IonCardSubtitle>Part #: </IonCardSubtitle>
                                </IonRow>
                                <IonRow class="container-props">
                                    {this.state.partNumber}
                                </IonRow>
                                
                                <IonRow>
                                    <IonCardSubtitle>Lot #: </IonCardSubtitle>
                                </IonRow>
                                <IonRow class="container-props">
                                    {this.state.lotNumber}
                                </IonRow>
                                
                                <IonRow>
                                    <IonCardSubtitle>Manufacturer Name: </IonCardSubtitle>
                                </IonRow>
                                <IonRow class="container-props">
                                    {this.state.manufacturerName}
                                </IonRow>
                                
                                <IonRow>
                                    <IonCardSubtitle>Manufactured Date: </IonCardSubtitle>
                                </IonRow>
                                <IonRow class="container-props">
                                    {this.getDateString(this.state.manufacturedDate.toDate())}
                                </IonRow>
                                
                                <IonRow>
                                    <IonCardSubtitle>SmartCert Record Created: </IonCardSubtitle>
                                </IonRow>
                                <IonRow class="container-props">
                                    {this.getDateString(this.state.createdDate.toDate())}
                                </IonRow>
                                
                                <IonRow>
                                    <IonCardSubtitle>SmartCert Record Last Modified: </IonCardSubtitle>
                                </IonRow>
                                <IonRow class="container-props">
                                    {this.getDateString(this.state.modifiedDate.toDate())}
                                </IonRow>
                            </IonCol>
                        </IonRow>
                        
                        <IonList>
                            { this.state.documents.map((document) =>

                                <IonCard color="secondary" key={document.id}>
                                    <IonCardHeader color="secondary">
                                        <IonCardTitle>{document.name}</IonCardTitle>
                                        <IonCardSubtitle>Created Date:{this.getDateString(document.createdDate.toDate())}</IonCardSubtitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonButton href={document.url}>Download</IonButton>
                                    </IonCardContent>
                                </IonCard>
                            )} 
                        </IonList>                        
                    </IonCardContent>
                </IonCard>
            </IonContent>
            </IonPage>
        );
    }
}

export default ContainerLookupPage